import _ from 'lodash'

export default {
	data() {
		return {
			
		}
	},
	props: [],
	created(){
		
	},
	methods: {
		deleteParcelleFavoris(parcelle_id){
			let self = this
			this.$dialog.confirm(self.$t('parcelle_delete_favoris_confirm'), { loader: true })
				.then(dialog => {
					dialog.close();
					self.$store.dispatch('delParcelleFavoris', parcelle_id)
				});
		},
		setParcelle(parcelle_id){
			this.$store.commit('UI_SET_CONTENT', {content: 'result'});
			this.$store.dispatch('setParcelleOnId', parcelle_id)
		}
	},
	computed:{
		
	},
	components:{
		SousMenu: () => import('../sous_menu.vue')
	}
}